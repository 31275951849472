import { useImagesOptimizedMapped } from '@shared/hooks/useImagesOptimizedMapped';
import { graphql, useStaticQuery } from 'gatsby';

export function useGetBannerOptimizedImages() {
  const imagesData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "assets" }
          extension: { in: ["png", "jpg"] }
          relativeDirectory: { eq: "images/banners" }
        }
      ) {
        nodes {
          name
          image: childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);

  return useImagesOptimizedMapped(imagesData);
}
