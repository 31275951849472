import { useIsOneOfSpecifiedLanguages } from '@shared/hooks/useIsOneOfSpecifiedLanguages';
import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';
import { useMastercardLandingUrl } from '@shared/hooks/useMastercardLandingUrl';
import React from 'react';
import { GenericLink } from '../../GenericLink';
import { MastercardVerticalContent } from './MastercardVerticalContent';
import { MastercardVideoVertical } from './MastercardVideoVertical';

function useComponentState() {
  const isSupportedLanguge = useIsOneOfSpecifiedLanguages('uk', 'en', 'de', 'fr');
  const mastercardLandingUrl = useMastercardLandingUrl();

  return {
    isSupportedLanguge,
    mastercardLandingUrl,
  };
}

export const MastercardVerticalBanner: React.FC = () => {
  const { isSupportedLanguge, mastercardLandingUrl } = useComponentState();

  return (
    <GenericLink
      type="outer"
      to={mastercardLandingUrl}
      className="relative w-full max-w-[388px] bg-gradient-to-bl from-[#7DD4F9] to-[#feffe4] no-underline rounded-lg overflow-hidden"
    >
      {isSupportedLanguge ? <MastercardVideoVertical /> : <MastercardVerticalContent />}
    </GenericLink>
  );
};
