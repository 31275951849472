import config from '@config';
import { MastercardHorizontalBanner } from '@shared/ui/banner/mastercard-horizontal-banner';
import { MastercardVerticalBanner } from '@shared/ui/banner/mastercard-vertical-banner';
import * as React from 'react';

const BannersPage = () => {
  if (!config.payments.mastercardBonusEnabled) return null;

  return (
    <div className="container_xl pt-6 pb-12 lg:pt-12 xl:pb-52">
      <MastercardHorizontalBanner />
      <MastercardVerticalBanner />
    </div>
  );
};

export default BannersPage;
