import { useLanguage } from '@hooks/useLanguage';
import React from 'react';

function useComponentState() {
  const currentLanguage = useLanguage();

  return { currentLanguage };
}

export const MastercardVideoWide: React.FC = () => {
  const { currentLanguage } = useComponentState();
  const videoSource = `/videos/mastercard-wide-${currentLanguage}.mp4`;

  return (
    <video loop autoPlay muted playsInline className="w-full max-h-[190px]">
      <source src={videoSource} type="video/mp4" />
      Вибачте, ваш браузер не підтримує відео.
    </video>
  );
};
