import fora from '@assets/images/partners/fora.svg';
import fozzy from '@assets/images/partners/fozzy.svg';
import mastercard from '@assets/images/partners/mastercard-with-text.svg';
import silpo from '@assets/images/partners/silpo.svg';
import thrash from '@assets/images/partners/thrash.svg';
import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBannerOptimizedImages } from '../useGetBannerOptimizedImages';

function useComponentState() {
  const { t } = useTranslation();
  const isUkLanguage = useIsSpecificLanguage('uk');
  const imagesOptimized = useGetBannerOptimizedImages();
  const handWithPhoneImage = imagesOptimized.find((v) => v.name === 'hand-with-phone-lg').content;

  const offerExtentedClass = clsx(
    'px-2 text-xs font-medium bg-orange text-white text-center rounded-t-lg uppercase',
    isUkLanguage ? 'py-3 text-xxxs' : 'py-[10px] text-xs',
  );

  return {
    t,
    handWithPhoneImage,
    offerExtentedClass,
  };
}

export const MastercardVerticalContent = () => {
  const { t, handWithPhoneImage, offerExtentedClass } = useComponentState();

  return (
    <>
      <p className={offerExtentedClass}>{t('mastercard-offer-extended')}</p>
      <div className="mt-4 px-[18px] pb-5">
        <div className="flex gap-x-4 w-full max-w-[548px]">
          <img src={mastercard} alt="Mastercard" className="w-[105px] h-[78px] shrink-0" />
          <p className="text-[24px] leading-[30px] font-bold uppercase text-right">{t('add-funds-to-donation')} *</p>
        </div>
        <div className="flex flex-col items-end gap-y-[18px] mt-12">
          <img src={silpo} alt="Silpo" className="w-[102px]" />
          <img src={fora} alt="Fora" className="w-[102px]" />
          <img src={fozzy} alt="Fozzy" className="w-[102px]" />
          <img src={thrash} alt="Thrash" className="w-[102px]" />
        </div>
        <div className="flex justify-end">
          <p
            className="relative w-full max-w-[284px] mt-12 z-10 text-[10px] leading-[1.2] uppercase text-right"
            dangerouslySetInnerHTML={{ __html: t('add-funds-to-donation-time-limit') }}
          ></p>
        </div>
        <GatsbyImage
          image={getImage(handWithPhoneImage)}
          alt="Phone icon"
          className="!absolute bottom-0 left-0 w-[274px] h-[379px]"
        />
      </div>
    </>
  );
};
