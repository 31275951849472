import React from 'react';
import { useLanguage } from '@hooks/useLanguage';

function useComponentState() {
  const currentLanguage = useLanguage();

  return { currentLanguage };
}

export const MastercardVideoVertical: React.FC = () => {
  const { currentLanguage } = useComponentState();
  const videoSource = `/videos/mastercard-short-${currentLanguage}.mp4`;

  return (
    <video loop autoPlay muted playsInline className="w-full">
      <source src={videoSource} type="video/mp4" />
      Вибачте, ваш браузер не підтримує відео.
    </video>
  );
};
