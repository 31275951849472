import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBannerOptimizedImages } from '../useGetBannerOptimizedImages';
import fora from '@assets/images/partners/fora.svg';
import fozzy from '@assets/images/partners/fozzy.svg';
import mastercard from '@assets/images/partners/mastercard.svg';
import silpo from '@assets/images/partners/silpo.svg';
import thrash from '@assets/images/partners/thrash.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

function useComponentState() {
  const { t } = useTranslation();
  const isUkLanguage = useIsSpecificLanguage('uk');
  const imagesOptimized = useGetBannerOptimizedImages();
  const handWithPhoneImage = imagesOptimized.find((v) => v.name === 'hand-with-phone-sm').content;
  const baloonHeartCenterImage = imagesOptimized.find((v) => v.name === 'baloon-heart-center').content;
  const baloonHeartSideImage = imagesOptimized.find((v) => v.name === 'baloon-heart-side').content;

  const offerExtentedClass = clsx(
    'uppercase',
    isUkLanguage
      ? 'mt-2 text-orange font-bold text-xxs'
      : 'absolute top-[19px] right-0 w-44 py-1 pl-2 pr-3 text-xxxxs bg-orange text-white text-right',
  );

  return {
    t,
    handWithPhoneImage,
    baloonHeartCenterImage,
    baloonHeartSideImage,
    offerExtentedClass,
  };
}

export const MastercardWideContent = () => {
  const { t, handWithPhoneImage, baloonHeartCenterImage, baloonHeartSideImage, offerExtentedClass } =
    useComponentState();

  return (
    <>
      <div className="flex w- gap-x-6 w-full max-w-[548px]">
        <img src={mastercard} alt="Mastercard" className="w-28 h-[70px] shrink-0" />
        <div>
          <p className="text-[33px] leading-[39px] font-bold uppercase">{t('add-funds-to-donation')} *</p>
          <p className={offerExtentedClass}>{t('mastercard-offer-extended')}</p>
        </div>
      </div>
      <div className="flex items-center gap-x-8 mt-[14px]">
        <img src={silpo} alt="Silpo" className="w-[109px]" />
        <img src={fora} alt="Fora" className="w-[122px]" />
        <img src={fozzy} alt="Fozzy" className="w-[98px]" />
        <img src={thrash} alt="Thrash" className="w-[94px]" />
      </div>
      <p
        className="relative mt-3 z-10 text-[9px] leading-[1.2] uppercase"
        dangerouslySetInnerHTML={{ __html: t('add-funds-to-donation-time-limit') }}
      ></p>
      <GatsbyImage
        image={getImage(handWithPhoneImage)}
        alt="icon"
        className="!absolute bottom-0 right-[102px] w-[195px] h-[190px]"
        imgStyle={{ willChange: 'auto' }}
      />
      <GatsbyImage
        image={getImage(baloonHeartCenterImage)}
        alt="icon"
        className="!absolute right-[265px] bottom-[26px] w-[70px] h-[70px]"
      />
      <GatsbyImage
        image={getImage(baloonHeartSideImage)}
        alt="icon"
        className="!absolute right-8 bottom-24 w-[44px] h-[44px]"
      />
    </>
  );
};
