import { useIsOneOfSpecifiedLanguages } from '@shared/hooks/useIsOneOfSpecifiedLanguages';
import { useMastercardLandingUrl } from '@shared/hooks/useMastercardLandingUrl';
import clsx from 'clsx';
import React from 'react';
import { GenericLink } from '../../GenericLink';
import { MastercardVideoWide } from './MastercardVideoWide';
import { MastercardWideContent } from './MastercardWideContent';

function useComponentState() {
  const mastercardLandingUrl = useMastercardLandingUrl();
  const isSupportedLanguge = useIsOneOfSpecifiedLanguages('uk', 'en', 'de', 'fr');
  const wrapperLinkClass = clsx(
    'relative w-full max-w-[948px] bg-gradient-to-b from-[#7DD4F9] to-[#feffe4] no-underline',
    !isSupportedLanguge && 'pt-6 px-8 pb-2 rounded-lg',
  );

  return {
    wrapperLinkClass,
    mastercardLandingUrl,
    isSupportedLanguge,
  };
}

export const MastercardHorizontalBanner: React.FC = () => {
  const { wrapperLinkClass, mastercardLandingUrl, isSupportedLanguge } = useComponentState();

  return (
    <GenericLink type="outer" to={mastercardLandingUrl} className={wrapperLinkClass}>
      {isSupportedLanguge ? <MastercardVideoWide /> : <MastercardWideContent />}
    </GenericLink>
  );
};
